import "./bootstrap"; //Laravel bootstrap js
import Vue from "vue";

window.Vue = Vue;

//Sentry Setup
import * as Sentry from "@sentry/vue";

if (import.meta.env.VITE_APP_ENV !== "dev") {
    Sentry.init({
        Vue,
        environment: import.meta.env.VITE_APP_ENV,
        //  release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
        dsn: import.meta.env.VITE_VUE_DSN,

        integrations: [
            Sentry.browserTracingIntegration({
                enableInp: true
            })
        ],
        // tracesSampleRate: 0.2,
        tracesSampleRate: import.meta.env.VITE_APP_ENV === "live-octane" ? 0.2 : 0.0,

        // Session Replay
        replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%.
        replaysOnErrorSampleRate: 0.0, // 100% when errors occur

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/www\.simplyowners\.net\//,
            /^https:\/\/solaravel\.lndo\.site\//,
            /^https:\/\/www\.sodev\.uk\//,
            /^https:\/\/www\.simplybnb\.uk\//,
            /^https:\/\/www\.simplybnb\.co\.uk\//
        ],

        tracingOptions: {
            trackComponents: true
        },
        // Vue specific
        logErrors: true,
        attachProps: true,
        attachStacktrace: true
    });
}
//Sentry Setup End

import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import Vue2TouchEvents from "vue2-touch-events";
import {store, mutations} from "./components/store"; //Required for store to be in app.js

const datepickerOptions = {
    sundayFirst: false,
    colors: {
        selected: "#589e00",
        inRange: "#81be32",
        selectedText: "#fff",
        text: "#565a5c",
        inRangeBorder: "#33dacd",
        disabled: "#fff",
        hoveredInRange: "#81e207"
    },
    texts: {
        apply: "Apply",
        cancel: "Clear dates"
    }
};

Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(Vue2TouchEvents);

import {webtextHide, menuToggle, ieRedirect} from "./native-js";

// Not dynamic imports as they increase Cumulative Layout Shift when dynamic
import SearchFormComponent from "@/components/SearchFormComponent.vue";
import PropertySliderComponent from "@/components/PropertySliderComponent.vue";

new Vue({
    el: "#app",
    components: {
        PropertySliderComponent,
        SearchFormComponent,
        LocationAdminComponent: () =>
            import("@/components/LocationAdminComponent.vue"),
        SliderComponent: () => import("@/components/SliderComponent.vue"),
        FilterSelectedComponent: () =>
            import("@/components/FilterSelectedComponent.vue"),
        ResultsComponent: () => import("@/components/ResultsComponent.vue"),
        LazyLoadMapComponent: () =>
            import("@/components/LazyLoadMapComponent.vue"),
        LazyLoadFilterComponent: () =>
            import("@/components/LazyLoadFilterComponent.vue"),
        FavesComponent: () => import("@/components/FavesComponent.vue"),
        PropertyContactComponent: () =>
            import("@/components/PropertyContactComponent.vue"),
        PropertyContactButtonsComponent: () =>
            import("@/components/PropertyContactButtonsComponent.vue"),
        CalendarComponent: () => import("@/components/CalendarComponent.vue"),
        ReviewsComponent: () => import("@/components/ReviewsComponent.vue"),
        CookieComponent: () => import("@/components/CookieComponent.vue"),
        SocialShareComponent: () =>
            import("@/components/SocialShareComponent.vue"),
        WebtextEditComponent: () =>
            import("@/components/WebtextEditComponent.vue"),
        PropertyPageChangeDate: () =>
            import("@/components/PropertyPageChangeDate.vue")
    },
    mounted() {
        webtextHide();
        menuToggle();
        ieRedirect();
    }
});
