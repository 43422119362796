<template>
    <div class="grid md:grid-cols-12 grid-cols-1">
        <div class="col-search col-geo">
            <input
                id="location-search"
                autocomplete="off"
                class="input-search"
                placeholder="Where to / Property ID"
                type="text"
                v-model="keywords"
                v-on:click="resetSearch()"
            />
            <span class="icon-location"></span>
            <div class="predictions" v-if="results.length > 0">
                <div
                    class="predictions__row"
                    v-for="(result, index) in results"
                    v-html="highlight(result)"
                    v-on:click="selectResult(result, index)"
                ></div>
            </div>
        </div>
        <div class="col-search col-date">
            <div id="index-date" class="datepicker-trigger">
                <input
                    @click="lazyDP"
                    title="Enter dates for availability"
                    class="input-search cursor-pointer"
                    type="text"
                    id="trigger-range"
                    :value="formatDates(date1, date2)"
                    placeholder="Enter dates for availability"
                    readonly
                />
                <span class="icon-calendar"></span>
                <airbnb-style-datepicker
                    v-if="isDPVisible"
                    :trigger-element-id="'trigger-range'"
                    :date-one="date1"
                    :date-two="date2"
                    :min-date="getToday"
                    v-on:date-one-selected="
                        val => {
                            date1 = val;
                        }
                    "
                    v-on:date-two-selected="
                        val => {
                            date2 = val;
                        }
                    "
                    :fullscreen-mobile="true"
                    :show-shortcuts-menu-trigger="false"
                    :close-after-select="true"
                    :offset-x="-10"
                    :offset-y="0"
                    @cancelled="onCancelMethod"
                    :mobile-header="'Select your dates'"
                    :trigger="showPicker"
                ></airbnb-style-datepicker>
            </div>
        </div>
        <div class="col-search col-button">
            <button
                v-on:click="loadSearch()"
                v-bind:disabled="!searchUrl"
                class="search-button"
                type="button"
                id="button_find"
            >
                FIND PROPERTIES
            </button>
        </div>
    </div>
</template>

<script>
import { store, mutations } from "./store.js";
import debounce from "lodash/debounce";

import format from "date-fns/format";
import addDays from "date-fns/add_days";
import startOfToday from "date-fns/start_of_today";
import differenceInCalendarDays from "date-fns/difference_in_calendar_days";

export default {
    name: "SearchFormComponent",
    props: ["location", "parsedUrl"],
    data() {
        return {
            storeState: store.state,
            selected: "",
            clicked: false,
            keywords: null,
            url: "",
            placeId: "",
            type: "",
            results: [],
            dest: [],
            searchOff: true,
            aid: "",
            geolock: null,
            isDPVisible: false,
            showPicker: false,
            date1: "",
            date2: "",
            today: "",
            dateFormat: "D MMM YYYY",
            arriveFormat: "D-MMM-YYYY"
        };
    },
    watch: {
        keywords(after, before) {
            if (this.keywords.length < 3) {
                this.results = [];
                this.sendPID(0);
            }
            if (/([pP])([0-9]{3,})/.test(this.keywords) === true) {
                this.sendPID(this.keywords.substr(1));
            } else if (this.keywords.length > 3 && !isNaN(this.keywords)) {
                this.sendPID(this.keywords);
            } else if (this.keywords.length > 3 && this.clicked === false) {
                this.debouncedGetResults();
                this.sendPID(0);
            }
        }
    },
    created() {
        this.debouncedGetResults = debounce(this.getResults, 400);
    },
    mounted() {
        if (typeof this.location !== "undefined") {
            this.url = this.location.gl_url_new;
            this.keywords = this.location.gl_typed;
            this.placeId = this.location.gl_google_place_id;
            this.clicked = true;
            this.type = "location";
            this.aid = this.location.gl_aid;
            this.destination = [];
            this.destination.push({
                url: this.url,
                placeId: this.placeId,
                type: this.type,
                keywords: this.keywords,
                aid: this.aid
            });
            mutations.setDestination(this.destination);
        }

        if (!this.parsedUrl) {
            this.parsedUrl = {};
        } //If parsedUrl undefined
        this.parsedUrl.nights = parseInt(this.parsedUrl.nights);
        if (this.parsedUrl.nights > 0) {
            var arrive = this.parsedUrl.arrive.split("-");
            var nights = this.parsedUrl.nights;
            mutations.setNights(nights);
            this.date1 = format(arrive, "YYYY-MM-DD");
            this.date2 = addDays(this.date1, nights);
        }
    },
    methods: {
        highlight(result) {
            let text = result.gl_typed;
            if (this.unrestrictedSearch === true) {
                text = this.adminPrefix(result.type) + text;
            }

            if (result.type === "country") {
                text = text.bold(); // If country make <b>
            }

            if (this.storeState.pid === 0) {
                return text.replace(
                    new RegExp(this.keywords.trim(), "gi"),
                    '<span style="color: #0099ff;font-weight: bold;">$&</span>'
                ); // Highlight search text
            }
        },
        resetSearch() {
            this.keywords = "";
            this.clicked = false;
            this.url = "";
            this.placeId = "";
            this.geolock = {};
            this.type = "";
            this.destination = [];
            mutations.setDestination(this.dest);
        },
        async selectResult(result, selected) {
            this.clicked = true;
            this.aid = this.results[selected]["gl_aid"];
            this.keywords = this.results[selected]["gl_typed"];
            this.url = this.results[selected]["gl_url_new"];
            this.placeId = this.results[selected]["gl_google_place_id"];
            this.type = this.results[selected]["type"];
            if (
                result.type === "google" ||
                (result.type === "country" && result.gl_url_new === null)
            ) {
                try {
                    const response = await axios.post("/api/geo-lock/", result);
                    this.geolock = response.data;
                    this.aid = this.geolock["gl_aid"];
                    this.keywords = this.geolock["gl_typed"];
                    this.url = this.geolock["gl_url_new"];
                    this.placeId = this.geolock["gl_google_place_id"];
                    this.type = this.geolock["type"];
                    this.clicked = true;
                    this.results = [];
                } catch (error) {
                    console.error(error);
                }
            }
            this.results = [];
            this.destination.push({
                url: this.url,
                placeId: this.placeId,
                type: this.type,
                keywords: this.keywords,
                aid: this.aid
            });
            mutations.setDestination(this.destination);
        },
        getResults() {
            axios
                .get("/api/geo-complete/" + this.keywords.trim(), {
                    params: {
                        ...(this.storeState.unrestricted === true
                            ? { u: 1 }
                            : {}) // If unrestricted is on, send query string to api
                    }
                })
                .then(response => (this.results = response.data))
                .catch(error => {
                    console.error(error);
                });
        },
        sendPID(pid) {
            mutations.setPID(pid);
        },
        forceRerender() {
            this.results.length = 0;
        },
        adminPrefix(type) {
            if (type === "location") {
                return "L> ";
            }
            if (type === "country") {
                return "C> ";
            }
            if (type === "google") {
                return "G> ";
            }
            if (type === "hidden") {
                return "H> ";
            }
            if (type === "redirect") {
                return "R> ";
            }
            if (type === "hidden-redirect") {
                return "HR> ";
            }
        },
        lazyDP() {
            if (this.isDPVisible === false) {
                new Promise(resolve => {
                    this.isDPVisible = true;
                    resolve();
                }).then(() => {
                    this.showPicker = true;
                });
            }
        },
        onCancelMethod: function() {
            this.date1 = "";
            this.date2 = "";
            this.storeState.arrive = "";
            this.storeState.nights = 0;
        },
        formatDates: function(date1, date2) {
            var formattedDates = "";
            if (date2) {
                formattedDates =
                    format(date1, this.dateFormat) +
                    " - " +
                    format(date2, this.dateFormat);
                this.storeState.arrive = format(
                    date1,
                    this.arriveFormat
                ).toLowerCase();
                this.storeState.nights = differenceInCalendarDays(date2, date1);
            }
            return formattedDates;
        },
        formatArrive: function(date1) {
            var formattedArrive = "";
            if (date1) {
                formattedArrive = format(
                    date1,
                    this.arriveFormat
                ).toLowerCase();
                this.storeState.arrive = formattedArrive;
            } else {
                formattedArrive = 0;
            }
            return formattedArrive;
        },
        formatNights: function(date1, date2) {
            var formattedNights = "";
            if (date1) {
                formattedNights = differenceInCalendarDays(date2, date1);
                this.storeState.nights = formattedNights;
            } else {
                formattedNights = 0;
            }
            return formattedNights;
        },
        loadSearch() {
            if (this.storeState.destination["0"]) {
                if (
                    this.storeState.destination["0"].type === "google" ||
                    this.storeState.destination["0"].url === null
                ) {
                    this.storeState.destination["0"].type = "google";
                    this.geoSetup(this.storeState.destination["0"]);
                } else {
                    window.location = this.searchUrl;
                }
            } else {
                window.location = this.searchUrl;
            }
        },
        async geoSetup(data) {
            try {
                const response = await axios.post("/api/geo-setup/", data);
                await mutations.updateUrl(response.data);
                window.location = this.searchUrl;
            } catch (ex) {
                console.error(ex);
            }
        }
    },
    computed: {
        unrestrictedSearch() {
            return store.state.unrestricted;
        },
        getToday: function() {
            let formattedToday = "";
            this.today = startOfToday();
            formattedToday = format(this.today, "YYYY-MM-DD");
            return formattedToday;
        },
        searchUrl() {
            if (this.storeState.destination.length > 0) {
                this.url =
                    window.location.origin +
                    "/" +
                    this.storeState.destination["0"].url +
                    "/";
                if (this.storeState.arrive > "" && this.storeState.nights > 0) {
                    this.url +=
                        "nights-" +
                        this.storeState.nights +
                        "/arrive-" +
                        this.storeState.arrive +
                        "/";
                }
                return this.url;
            }
            if (this.storeState.pid > 0) {
                this.url =
                    window.location.origin + "/p/" + this.storeState.pid + "/";

                if (this.storeState.arrive > "" && this.storeState.nights > 0) {
                    this.url +=
                        this.storeState.arrive +
                        "/" +
                        this.storeState.nights +
                        "/";
                }

                return this.url;
            }
        }
    }
};
</script>
